td {
    padding:0.2rem 0.8rem;
    text-align: right;
  }
tr:nth-child(even) {
    background-color:lightgrey;
  }
thead{
  text-align: center;
  border-bottom: 1px;
  border-bottom-style: solid;
}
table{
  font-family: monospace;
}
.tableContainer{
  overflow-x: auto;
}